import React, { useEffect, useState } from "react"

const ContactForm = () => {
  const [contactForms, setContactForms] = useState(null)
  const [refWrapper, setRefWrapper] = useState(null)
  const [status, setStatus] = useState(null)

  useEffect(() => {
    fetch(
      `https://cms.fintek.pl/wp-json/contact-form-7/v1/contact-forms/54693/structure`
    )
      .then(response => response.json())
      .then(data => {
        setContactForms(data)
      })
  }, [])

  useEffect(() => {
    if (!refWrapper) return

    refWrapper.querySelector('input[type="submit"]').addEventListener(
      "click",
      e => {
        e.preventDefault()

        for (const error of refWrapper.querySelectorAll("div.error")) {
          error.parentNode.removeChild(error)
        }

        const inputs = [
          refWrapper.querySelectorAll('input:not([type="submit"])'),
          refWrapper.querySelectorAll("select"),
          refWrapper.querySelectorAll("textarea"),
        ]
          .reduce((acc, i) => [...acc, ...(i ?? [])], [])
          .map(i => {
            return { name: i.name, value: i.value }
          })

        const formData = new FormData()
        for (const f of inputs) {
          formData.append(f.name, f.value)
        }

        fetch(
          "https://cms.fintek.pl/wp-json/contact-form-7/v1/contact-forms/54693/feedback",
          {
            method: "POST",
            body: formData,
          }
        )
          .then(response => response.json())
          .then(data => {
            if (data?.invalid_fields) {
              for (const field_data of data.invalid_fields) {
                const message = document.createElement("div")
                message.innerHTML = field_data?.message
                message.className = "error"

                const field = refWrapper.querySelector(field_data?.into)
                field.parentNode.append(message)
              }

              const message_form = document.createElement("div")
              message_form.innerHTML = data?.message
              message_form.className = "error"

              const btn = refWrapper.querySelector('input[type="submit"]')
              if (btn?.parentNode)
                btn.parentNode.insertBefore(message_form, btn)
            } else {
              setStatus("mail_sent_ok")
            }
          })
          .catch(error => {
            setStatus("mail_sent_ng")
          })
      },
      false
    )
  }, [contactForms, refWrapper])
  return (
    <>
      {status != null ? (
        <>
          {contactForms?.properties?.messages?.[status] && (
            <div
              className="contact-form message-sended"
              dangerouslySetInnerHTML={{
                __html: contactForms?.properties?.messages?.[status] ?? "",
              }}
            ></div>
          )}
        </>
      ) : (
        <>
          {contactForms && (
            <div
              className="contact-form"
              ref={el => setRefWrapper(el)}
              dangerouslySetInnerHTML={{ __html: contactForms?.html }}
            ></div>
          )}
        </>
      )}
    </>
  )
}
export default ContactForm
