/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from "prop-types"
import React from "react"
import { autop } from '@wordpress/autop';
import { useStaticQuery, graphql, navigate } from 'gatsby'

const PostContent = ({ content, className }) => {
  const { nofollow, noindex } = useStaticQuery(graphql`
    query {
      nofollow: allMysqlPost(filter: {nofollow: {eq: 1}}) {
        values: distinct(field: post_name)
      }
      noindex: allMysqlPost(filter: {indexables: {elemMatch: {is_robots_noindex: {in: [1]}}}}) {
        values: distinct(field: post_name)
      }
    }
  `);

  const newContent = content
    .replace(/\[.*?\]/g, '') // remove shortcodes
    .replace(new RegExp('https://fintek.pl/wp-content', 'gm'), 'https://static.fintek.pl') // fix images
    .replace(new RegExp('<a[\\s]+([^>]+)>((?:.(?!\\<\\/a\\>))*.)<\\/a>', 'gm'), (match) => {
      if (noindex.values.some((value) => match.includes(value)) || nofollow.values.some((value) => match.includes(value))) { // remove noindex / nofollow links
        return match
          .replace('<a', '<span')
          .replace('a>', 'span>')
          .replace('href=', 'data-anchor=');
      }

      return match;
    });

  const handleClick = (e) => {
    if (e.target.dataset.anchor && e.target.nodeName === 'SPAN') {
      navigate(e.target.dataset.anchor)
    }
  }

  return (
    <div
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: autop(newContent) }} 
      className={['post-content',className].join(' ')}
    />
  )
}

PostContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default PostContent
