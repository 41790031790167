import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import bootstrap from "../components/bootstrap.module.css"
import SEO from "../components/seo"
import PostContent from "../components/post-content"
import Breadcrumbs from "../components/breadcrumbs"
import ContactForm from "../components/contact-form"

export default function Page({ pageContext, data }) {
  const row = data.mysqlPage
  const seo = data.seo
  const slug = pageContext?.slug

  return (
    <Layout>
      <SEO
        canonical={
          seo && seo.canonical
            ? seo.canonical
            : "https://fintek.pl/" + row.post_name + "/"
        }
        title={seo && seo.title ? seo.title : row.post_title}
        description={seo ? seo.description : ""}
        robots={
          seo
            ? [
                seo.is_robots_noindex ? "noindex" : "index",
                seo.is_robots_nofollow ? "nofollow" : "follow",
              ].join(", ")
            : "index, follow"
        }
      />
      <Breadcrumbs post={row} />
      <h1 className={bootstrap.mb3}>{row.post_title}</h1>
      <hr className={bootstrap.mb4} />
      <PostContent content={row.post_content} className={"font-serif-light"} />
      {slug == "zapisz-sie-na-fintek-brunch" && <ContactForm />}
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($slug: String, $id: Int) {
    mysqlPage: mysqlPage(post_name: { eq: $slug }) {
      id
      post_date
      post_title
      post_content
      post_name
    }
    seo: mysqlIndexable(object_type: { eq: "post" }, object_id: { eq: $id }) {
      title
      description
      object_id
      object_type
      permalink
      breadcrumb_title
      canonical
      is_robots_noindex
      is_robots_nofollow
    }
  }
`
